import React, { lazy } from "react";
import { createRoot } from "react-dom/client"; // React 18 버전 업데이트로 변경
// import ReactDOM from 'react-dom';
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil"; //전역 상태 관리 추가

// Lazy load V2App
const V2App = lazy(() => import("./V2App"));

const App = lazy(() => import("./App"));

const location = window?.location?.pathname;
const pathname: string | null = getUserPath(location) || "/";

function getUserPath(pathname: string | null): string | null {
  if (!pathname) {
    return null;
  }

  const paths = pathname.split("/");
  const firstPath = paths[1];

  if (firstPath === "") {
    return "/";
  } else if (firstPath === "v2") {
    return "/v2";
  } else {
    return "/";
  }
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <RecoilRoot>
    {pathname === "/" && <App />}
    {pathname === "/v2" && <V2App />}
  </RecoilRoot>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
